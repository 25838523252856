:root {

  .mat-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-raised-button {
    @apply rounded-button min-w-[6rem];
  }

  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply rounded-button;

    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply pl-4;
    }

    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply pr-4;
    }
  }

  .mat-button-toggle-button {
    @apply font-medium;
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-primary {
    @apply text-white;
    background-image: linear-gradient(to left, #f77334, #fb6346, #fb5457, #f74869, #ef3f7a);
  }

  .btn {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;
  }
}
