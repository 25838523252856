:root {
  .mat-select-panel {
    @apply rounded shadow p-2;
    translate: unset !important;
    min-width: 100% !important;
  }
  .cdk-overlay-pane:has(.mat-select-panel-wrap) {
    transform: translateX(0px) translateY(30px) !important;
  }
  .mat-select-value {
    text-align: left;
  }

  .mat-option {
    @apply rounded text-sm font-medium h-10 transition duration-100 ease-out;

    &:hover,
    &.mat-active {
      @apply bg-primary/10;

      .mat-icon {
        @apply text-primary;
      }
    }

    .mat-icon {
      @apply transition duration-100 ease-out;
      font-size: 1.25rem;
      height: 1.25rem;
      width: 1.25rem;
    }

    &.mat-selected:not(.mat-option-multiple) {
      @apply bg-primary/20;
    }
  }

  .mat-menu-item-submenu-trigger {
    @apply pr-12;
  }
}
